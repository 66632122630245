.component-orientation-remplissage-manager nav.navbar.navbar-light.bg-white.box-shadow {
    margin-bottom: 0 !important;
}
.component-orientation-remplissage-manager .bordure-bas {
    border-bottom: 0 solid transparent !important;
}
.component-orientation-remplissage-manager section#cd_container {
    background: #273375 !important;
}
.orm-add-btn-col {
    text-align: center;
    margin-bottom: 25px;
}

.orm-add-btn {
    padding: 25px !important;
    border-radius: 50% !important;
    background: #fff !important;
    border: 5px solid #273375 !important;
    width: 100px;
    height: 100px;
}
.add-plus-icon {
    color: #273375 !important;
    width: 40px !important;
    height: 40px !important;
}
.orm-table-container {
    background: #fff !important;
    padding: 20px;
    border-radius: 10px;
}
.orm-table-container .react-bs-table-container input.form-control {
    padding: 23px;
    border-radius: 50px ;
    width: 100%;
    background-image: none !important;
    border-color: #273375 ;
}
.react-bs-table-container .my-custom-class {
    padding: 11px;
    border-radius: 50px;
    top: -8%;
    color: #fff !important;
    font-weight: 600;
    background: rgb(230, 65, 75) !important;
    border: 1px solid rgb(230, 65, 75) !important;
    position: absolute;
    right: -1%;
}
.react-bs-table-container button#pageDropDown {
    width: 90px;
    color: #fff !important;
    font-weight: 600;
    font-size: 19px;
    background: #273375 !important;
    border: 1px solid #273375 !important;
}
.react-bs-table-container thead table th {
    color: #273375 !important;
    font-size: 17px !important;
}
.orm-table-col {
    margin-bottom: 100px;
}
.oe-modal .modal-header h5 {
    color: #273375 !important;
    font-size: 23px;
    font-weight: 700 !important;
    margin-bottom: 40px;
}
.oe-modal .close span {
    color: #ff0000;
    font-size: 20px !important;
}
.oe-modal form label {
    font-size: 17px;
    color: #273375 !important;
    font-weight: 600;
}
.add-event-col {
    margin-bottom: 25px;
}
.add-event-form-control {
    background-image: none !important;
    height: 45px;
    border-radius: 50px;
    border-color: #273375 !important;
}
.add-event-col select {
    height: 45px !important;
    border-color: #273375 !important;
}
.add-event-submit-btn {
    width: 100%;
    padding: 13px !important;
    background: #273375 !important;
    border: 1px solid #273375 !important;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 600;
    border-radius: 50px;
}
.add-event-submit-btn:hover {
    background: #fff !important;
    color: #273375 !important;
}
.oe-modal .modal-dialog .modal-content {
    border-radius: 25px !important;
}
.linkable.event_type_item {
    font-weight: 600;
    hyphens: auto;
    word-wrap: break-word;
    white-space: normal;
    text-align: justify !important;
}
.tabler-position{
    position: relative;
}
.tabler-position .react-bs-table-container .react-bs-table-search-form {
    top: -25% !important;
}
.export-pdf-btn {
    padding: 12px;
    background: #fff !important;
    color: #273375 !important;
    font-weight: 900;
    border: 1px solid #FFFFFF !important;
    border-radius: 50px;
}
.export-pdf-btn:hover {
    background: #273375 !important;
    color: #fff !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
}
.admin-radio-input-btn-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.radio-input-container {
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    align-items: center;
}
.radio-input-label {
    font-size: 16px;
    font-weight: 500;
}
.admin-radio-btn-in-form {
    width: 18px;
    height: 18px;
}
.v2-select-form-control .dropdown-container {
    border: none;
    border-radius: 10px;
    margin-top: 1%;
}
.rmsc.form-control.oe-form-control.v2-select-form-control.pp {
    padding: 0;
}
.v2-select-form-control .dropdown-container:focus-within {
    border: none !important;
    box-shadow: none !important;
}
.event-manager-global-btn-actions-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}
.orm-global-filter-btn {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background: #C10046 !important;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.two-icons-in-button-container svg {
    width: 43px !important;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    color: #ffffff;
}
.two-icons-in-button-container {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
}
.plus-fe-icon {
    font-size: 20px;
    color: #ffffff;
}