body {
  margin: 0;
  /* font-family: 'Poppins' !important; */
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
nav#homepage-header-container {
  height: 200px;
} */

body,
html {
  height: 100vh;
}

.hidden-desktop {
  display: none;
}

.hidden-mobile {
  display: block;
}

sub.exp {
  bottom: 15px;
  text-transform: lowercase !important;
}

/********************* Moumadjad style ****************/

/* top */

.padd-menu {
  padding: 2% 10%;
}

.menu {
  font-size: 25px;
  color: #fff;
}

.menu:hover {
  font-size: 25px;
  color: #fff;
  text-decoration: underline;
}

.display-inl-b {
  display: inline-block;
}

.padd-top {
  padding: 2% 5% 5% 5%;
}

.padd-titre {
  padding: 5% 0% 10% 0%;
}

/* Partenaires */

.partenaires {
  padding: 15% 10%;
  background: #53a8dc;
}

.b-partenaires {
  background: #fff;
  display: inline-flex;
}

.img-partenaires {
  width: 100%;
  min-height: 170px;
  margin-bottom: 15px !important;
}

.p-partenaires {
  padding: 50px 170px;
}

.box-shadow {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
}

/* Apropos */

.apropos {
  background: rgb(133, 90, 163);
  background: linear-gradient(
    190deg,
    rgba(133, 90, 163, 1) 0%,
    rgba(95, 148, 205, 1) 100%
  );
  padding: 10% 10% 15% 15%;
}

.b-apropos {
  background: #fff;
  display: inline-flex;
  padding: 5% 10% 5% 2%;
}

.txt-apropos {
  color: #8459a1;
}

.capitalise {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.float {
  float: left;
}

.back-white {
  background: #fff;
}

.color-white {
  color: #fff;
}

.color-grey {
  color: grey;
}

.footer {
  background: #585858;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.p-60 {
  padding: 60px;
}

.p-70 {
  padding: 70px;
}

.p-80 {
  padding: 80px;
}

.p-90 {
  padding: 90px;
}

.p-100 {
  padding: 100px;
}

.p-block-15 {
  padding-block: 15px;
}

.p-block-20 {
  padding-block: 20px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-50 {
  font-size: 50px;
}

.fs-100 {
  font-size: 100px;
}

.mr-6 {
  margin-right: 6rem;
}

.border-none {
  border: none;
}

/* Inscription */

.bg-inscription {
  width: 100%;
  background: linear-gradient(
      156deg,
      rgba(138, 111, 173, 0.4094771697741597) 0%,
      rgba(138, 111, 173, 0.258216665572479) 100%
    ),
    url("https://gnb-test.imgix.net/wp-content/uploads//2019/04/Naomi.-Mariposas.-Fran-Afonso.-11.jpg?crop=faces&w=901&h=506.8125&fit=crop&fm=pjpg");
  height: auto;
  /* background: violet; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bordure-inscription-l-r {
  padding: 50px;
}

.bordure-inscription {
  padding: 50px;
}

.sexe {
  margin-top: 25px;
  color: #fff !important;
  padding-left: 25px;
}

.inscription-left {
  background-color: rgba(56, 85, 171, 0.7);
  width: 100%;
  height: auto;
}

.inscription-right {
  background-color: #fff;
  width: 100%;
  height: auto;
}

.btn-inscription {
  background: #273375 !important;
  padding: 10px;
  width: 100%;
  border: 2px solid #273375;
  border-radius: 0;
  color: #fff !important;
  font-weight: 600 !important;
}

.btn-inscription:hover {
  background: #fff !important;
  width: 100%;
  color: #273375 !important;
  border-color: #273375 !important;
  border-radius: 0;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

/* textarea:focus, input:focus {
border-color: transparent !important;
} */

/*---------------------------------------------*/

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/

.txt1 {
  /* font-family: Poppins-Regular; */
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.txt2 {
  /* font-family: Poppins-Regular; */
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
}

.bg1 {
  background-color: #3b5998;
}

.bg2 {
  background-color: #1da1f2;
}

.bg3 {
  background-color: #ea4335;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wrap-login100 {
  width: 500px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  /* font-family: Poppins-Bold; */
  font-size: 39px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
}

.titre-inscription {
  font-weight: 600;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.label-input100 {
  /* font-family: Poppins-Regular; */
  font-size: 14px;
  color: #fff;
  line-height: 1.5;
  padding-left: 7px;
}

.input100 {
  /* font-family: Poppins-Medium; */
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 55px;
  background: none;
  padding: 0 7px 0 43px;
}

.color-inscr-right {
  color: #273375 !important;
}

.compte-etude {
  padding: 0;
}

.inscr-right {
  font-size: 16px;
  color: #273375;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 55px;
  padding: 0;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #fff;
  font-size: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 0px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  background: #fff;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input100:focus + .focus-input100::after {
  color: #fff;
}

.has-val.input100 + .focus-input100::after {
  color: #fff;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
  .cmessage-panel-side {
    /* height: 54% !important; */
    position: absolute;
    right: 0;
    top: 24%;
    width: 79% !important;
  }
}

/*//////////////////////////////////////////////////////////////////
[ Social item ]*/

/*//////////////////////////////////////////////////////////////////
[ Responsive ]*/

@media (max-width: 576px) {
  .hidden-mobile-menu {
    display: none;
  }
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .cmessage-panel-side {
    /* height: 54% !important; */
    position: absolute;
    right: 0;
    top: 24%;
    width: 79% !important;
  }
}

/* Compteur */

@import url("https://fonts.googleapis.com/css?family=Montserrat");
.wrapper {
  position: relative;
  height: 12em;
}

.countdown-container {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.countdown {
  display: flex;
  transform-style: preserve3d;
  perspective: 500px;
  height: 10rem;
  width: 64em;
  margin: 0 auto;
}

.countdown.remove {
  animation: hide-countdown 1s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
  overflow: hidden;
}

.number,
.separator {
  display: block;
  color: #fff;
  height: 10rem;
  font-size: 10rem;
  position: relative;
  line-height: 10rem;
  text-align: center;
  width: 100%;
}

.separator {
  margin: 0;
  width: 2rem;
}

.new,
.old,
.current {
  color: #e5b336;
  position: absolute;
  border-radius: 1rem;
  height: 10rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.new {
  animation: show-new 0.4s cubic-bezier(0, 0.9, 0.5, 1.2) forwards;
}

.old {
  animation: hide-old 2s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
}

.countdown section {
  position: relative;
}

#js-days:after,
#js-hours:after,
#js-minutes:after,
#js-seconds:after {
  content: "JOURS";
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: -80px;
  font-size: 20px;
}

#js-hours:after {
  content: "HEURES";
}

#js-minutes:after {
  content: "MINUTES";
}

#js-seconds:after {
  content: "SECONDES";
}

@keyframes hide-countdown {
  to {
    height: 0;
    overflow: hidden;
  }
}

@keyframes show-new {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-2rem) scale(0.8)
      rotateX(-20deg);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes hide-old {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-5rem) scale(0.5)
      rotateX(-75deg);
  }
}

.w-full {
  width: 100%;
}

/* Pour les smartphone */

@media (max-width: 758px) {
  .hidden-mobile-menu {
    display: none;
  }
  .cmessage-panel-side {
    /* height: 54% !important; */
    position: absolute;
    right: 0;
    top: 24%;
    width: 79% !important;
  }
  .links-panel-side {
    position: absolute;
    right: 0;
    top: 24%;
    height: 50% !important;
    width: 69% !important;
  }
  .col-md-6.offset-md-3.suite-btn {
    margin-top: -7px;
  }
  .bordure-inscription {
    padding: 50px 0;
  }
  .bordure-inscription-l-r {
    padding: 50px 25px;
  }
  .img-top {
    width: 100%;
    /* height: 100vh; */
    background: linear-gradient(
        190deg,
        rgba(133, 90, 163, 0.804435152967437) 0%,
        rgba(133, 90, 163, 0.6475724078693977) 100%
      ),
      url("./img/bg/top.jpg");
    /* background-image: url('./img/bg/top.jpg'); */
    /* background: violet; */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .padd-menu {
    display: none;
  }
  .menu {
    font-size: 25px;
    color: #fff;
  }
  .menu-mobile {
    padding: 15px;
    background: #fff;
  }
  .menu:hover {
    font-size: 25px;
    color: #fff;
    text-decoration: underline;
  }
  .display-inl-b {
    display: inline-block;
  }
  .padd-top {
    padding: 2% 5% 5% 5%;
  }
  .padd-titre {
    padding: 12% 0% 10% 0%;
  }
  /* partenaires */
  .partenaires {
    padding: 6% 5% 4% 5%;
    background: #53a8dc;
  }
  .b-partenaires {
    background: #fff;
    display: inline-flex;
  }
  .img-partenaires {
    width: 100%;
    height: 60px;
  }
  .p-partenaires {
    padding: 10px;
  }
  /* Avantages */
  .avantages {
    background: #599bd1;
    padding: 6% 5% 4% 5%;
  }
  .p-5 {
    padding: 0 !important;
  }
  /* Compteur */
  .wrapper {
    position: relative;
    height: 10em;
  }
  .countdown-container {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  .countdown {
    display: flex;
    transform-style: preserve3d;
    perspective: 500px;
    height: 6rem;
    width: 100%;
    margin: 0 auto;
  }
  .countdown.remove {
    animation: hide-countdown 1s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
    overflow: hidden;
  }
  .number,
  .separator {
    display: block;
    color: #fff;
    height: 4rem;
    font-size: 2rem;
    position: relative;
    line-height: 10rem;
    text-align: center;
    width: 100%;
  }
  .separator {
    margin: 0;
    width: 2rem;
  }
  .new,
  .old,
  .current {
    color: #e5b336;
    position: absolute;
    border-radius: 1rem;
    height: 10rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .new {
    animation: show-new 0.4s cubic-bezier(0, 0.9, 0.5, 1.2) forwards;
  }
  .old {
    animation: hide-old 2s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
  }
  .countdown section {
    position: relative;
  }
  #js-days:after,
  #js-hours:after,
  #js-minutes:after,
  #js-seconds:after {
    content: "JOURS";
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: -80px;
    font-size: 13px;
  }
  #js-hours:after {
    content: "HEURES";
  }
  #js-minutes:after {
    content: "MINUTES";
  }
  #js-seconds:after {
    content: "SECONDES";
  }
  @keyframes hide-countdown {
    to {
      height: 0;
      overflow: hidden;
    }
  }
  @keyframes show-new {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(-2rem) scale(0.8)
        rotateX(-20deg);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }
  @keyframes hide-old {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(-5rem) scale(0.5)
        rotateX(-75deg);
    }
  }
  .mt-m-5 {
    margin-top: 0 !important;
  }
  .primo {
    background: rgb(136, 71, 218);
    background: linear-gradient(
      156deg,
      rgba(136, 71, 218, 0.9528945367209384) 0%,
      rgba(26, 188, 254, 0.6167600829394257) 100%
    );
    width: 100%;
    padding: 5% 0% 5% 0%;
  }
}

/* HALLES */

.header {
  padding: 2%;
}

.logo-halles {
  width: 46%;
}
.component-liste-halles {
  height: 100vh;
}

/* .primo {
  background: rgb(136, 71, 218);
  background: linear-gradient(156deg, rgba(136, 71, 218, 0.9528945367209384) 0%, rgba(26, 188, 254, 0.6167600829394257) 100%);
  width: 100%;
  padding: 5% 15% 5% 15%;
} */

.topnav .search-container {
  float: right;
}

/* .topnav input[type=text] {
padding: 6px;
margin-top: 8px;
font-size: 17px;
border: none;
} */

.topnav .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #ccc;
}

.recherche {
  padding: 15px 30px;
  width: 100%;
  border: 1px solid #ececec;
}

.limite {
  border: 1px solid rgb(14, 120, 161);
  padding: 45px 30px;
}

.limite:hover {
  border: 1.5px solid rgb(68, 140, 168);
  padding: 45px 30px;
}

.img-haulles {
  width: 100%;
  margin-bottom: 30px;
}

.h-40 {
  height: 50px;
}

.vertical-menu {
  width: 150px;
  background: #a276c966;
  position: fixed;
  right: 0px;
  text-align: center;
  z-index: 999;
  transition: none;
  font-size: 14px;
  top: 5px;
  padding: 10px 10px;
  border: 1px solid #fff;
}
.react-bs-table-container {
  background: #fff !important;
  padding: 20px;
  border-radius: 10px;
}

.vertical-menu .color-menu {
  font-size: 13px !important;
  color: #273375 !important;
  padding-left: 10px;
}

.case {
  width: 100%;
  padding-block: 5px;
  text-align: left;
}

/**********************/

/*
    DEMO STYLE
*/

/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; */

body {
  /* font-family: 'Poppins', sans-serif; */
  background: #fafafa;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}
select option {
  background: #dddddd !important;
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
  width: 300px;
  position: fixed;
  top: 0;
  left: -300px;
  height: auto;
  z-index: 999;
  background: #835fb3;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar.active {
  left: 0;
}

#dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #a259ff;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#dismiss:hover {
  background: #fff;
  color: #a259ff;
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  top: 0;
}

.overlay.active {
  display: block;
  opacity: 1;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #8246d1;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul p {
  color: #fff;
  padding: 20px 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #a259ff;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6b3aac;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 59% !important;
  right: 40%;
  transform: translateY(-50%);
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #a259ff;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.btn-side {
  color: #212529 !important;
  font-size: 22px;
}

.opt {
  padding: 0px 20px 50px 20px !important;
}

.left {
  text-align: left;
}

.no-bordure {
  border-radius: 0px !important;
}

.position {
  color: #fff;
  font-weight: 600;
}

.cost-sel {
  height: 50px;
  background: #d7c2f1;
  color: #fff;
}

.color-menu {
  color: #fff !important;
  font-size: 18px;
}

.color-menu:hover {
  /* color: #8459a1 !important; */
  color: #46b9e8 !important;
  font-weight: 600;
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #000;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
a.nav-link.register-home-btn {
  border: 2px solid #000000;
  color: #000000 !important;
  font-weight: 600;
}
.search-input-container {
  position: relative;
  /* top: 28%; */
}
.show-mobile-menu {
  opacity: 0;
  display: none;
}
.mobile-hidden-desktop,
.hide-desktopy,
.desktop-hide-meu-logo {
  display: none;
}
.exposant-show-mobile-menu,
.guest-hidden-mobile-menu {
  display: none;
}
.favy {
  display: inline;
}
.show-item-stand-conf-desktop {
  display: block;
}

@media (min-width: 769px) and (max-width: 1210px) {
  .show-item-stand-conf-desktop {
    display: none;
  }
  .hide-desktopy {
    display: block;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    margin-left: 33.333333%;
  }
}

.dsk-hidden-part {
  display: none;
}

/* Pour mobile */

@media (max-width: 768px) {
  span.jourj-number-1 {
    font-size: 39px;
  }
  span.jourj-number-2 {
    display: none;
  }
  .dsk-hidden-part {
    display: inline;
  }
  h1.jourj-label {
    font-size: 48px;
    margin-bottom: 30px;
  }
  .alert.closed-stand-alert {
    top: 93%;
  }
  .responsive-update-vprofile {
    margin-top: 280px !important;
  }
  .exposant-show-mobile-menu,
  .guest-hidden-mobile-menu {
    display: block;
  }
  .confying {
    height: 340px;
  }
  .search-input-container {
    top: 12%;
  }
  .desktop-hide-meu-logo,
  .show-item-stand-conf-desktop {
    display: none;
  }
  .resp-style {
    position: fixed;
    bottom: 0;
    background: #9b49e0;
    right: 0;
    left: 0;
    margin: auto;
    padding: 15px;
    z-index: 99;
  }
  .hide-desktopy {
    display: block;
  }
  .row.hide-desktopy {
    width: 100%;
  }
  button.blob-btn.pulse-podium {
    margin: 10px 0;
  }
  .pos-chiffre {
    right: 23%;
  }
  .menu-stand {
    width: 16.5% !important;
  }
  .__talkjs_launcher {
    bottom: 11% !important;
    right: 3% !important;
    background-color: #1a5691 !important;
  }
  .hide-item {
    display: none !important;
  }
  .hide-item-tc {
    text-align: center;
  }
  .hide-item-ib {
    display: inline;
  }
  .row.sv-row {
    padding-bottom: 40% !important;
  }
  .bordure-bas {
    padding-bottom: 10px !important;
  }
  .go-back-btn {
    text-align: center;
    margin-top: 15px;
  }
  .hidden-mobile-menu {
    display: none;
  }
  .mobile-hidden-desktop {
    display: block;
  }
  .show-mobile-menu {
    opacity: 1;
    display: contents;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cmessage-panel-side {
    /* height: 54% !important; */
    position: absolute;
    right: 0;
    top: 24%;
    width: 79% !important;
  }
  .links-panel-side {
    position: absolute;
    right: 0;
    top: 24%;
    height: 50% !important;
    width: 69% !important;
  }
  .logo-halles {
    width: 100%;
    padding: 10px 10px;
    position: relative;
    right: -70px;
  }
  .visiteur-header .logo-halles {
    /* width: 47%; */
    padding: 0px;
    position: relative;
    right: 0px;
  }
  header.header.visiteur-header {
    height: 150px;
    background: #fff;
  }
  h1.halle_page_title {
    font-weight: 900;
    display: none;
  }
  .primo {
    padding: 5% 5% 5% 5%;
  }
  .vertext {
    display: none;
  }
  .vertical-menu {
    width: 50px;
    position: fixed;
    right: 0px;
    text-align: center;
    z-index: 999;
    transition: none;
    font-size: 14px;
    top: 250px;
    padding: 49px 7px;
  }
  .color-menu {
    color: #fff;
  }
}

/***********************************************************************/

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
  .hidden-mobile {
    display: none;
  }
  .hidden-desktop {
    display: block;
  }
  span.intro1 {
    font-size: 40px;
  }
  section#homepage {
    height: 839px;
  }
  span.intro2 {
    font-size: 32px;
  }
  .salon-img-container {
    display: none;
  }
  .pr-text p {
    text-align: justify !important;
  }
  p.welcome {
    font-size: 15px !important;
    text-align: justify !important;
  }
  p.welcome-body {
    font-size: 15px !important;
    margin-top: 0 !important;
    text-align: justify !important;
  }
  .col-md-6.align-self-end.homagepage-btn-container {
    margin-top: 0px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
  .hidden-mobile {
    display: none;
  }
  .hidden-desktop {
    display: block;
  }
  span.intro1 {
    font-size: 40px;
  }
  section#homepage {
    height: 839px;
  }
  span.intro2 {
    font-size: 32px;
  }
  /* .salon-img-container {
    display: none;
  } */
  .pr-text p {
    text-align: justify;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (max-width: 768px) {
  .cmessage-panel-side {
    /* height: 54% !important; */
    position: absolute;
    right: 0;
    top: 24%;
    width: 79% !important;
  }
  .links-panel-side {
    position: absolute;
    right: 0;
    top: 24%;
    height: 50% !important;
    width: 69% !important;
  }
  .salon-img-container {
    display: none;
  }
  .col-md-8.pr-text {
    max-width: 100%;
    width: 100%;
    flex: inherit;
  }

  .mh {
    display: none;
  }

  .pr-text p {
    margin-right: 0px;
  }

  button.btn.btn-indigo.btn-md.ipen-modal-btn {
    width: 100%;
  }

  .rg-btn-cont {
    flex: auto;
    max-width: 100%;
    width: 100%;
  }
  .principal-bloc.wow.fadeInRight {
    flex: auto;
    max-width: 78%;
    margin-left: 11%;
  }
  .col-md-6.bordure-inscription-l-r.inscription-left,
  .col-md-6.bordure-inscription-l-r.inscription-right {
    flex: auto;
    max-width: 100%;
  }
  .col-md-6.bordure-connexion-l-r.connexion-left,
  .col-md-6.bordure-connexion-l-r.connexion-right {
    flex: auto;
    max-width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  .hidden-mobile {
    display: block;
  }
  .hidden-desktop {
    display: none;
  }
}

@media only screen and (min-width: 1500px) {
  .mesp-carousel-item-legend-header {
    font-size: 60px !important;
  }
  .mesp-carousel-item-legend-slogan {
    font-size: 30px !important;
  }
  .mesp-slide-item-action-btn {
    padding: 20px 90px !important;
    font-size: 30px !important;
  }
  .mesp-ae-home-intro-textual {
    font-size: 30px !important;
  }
  .mesp-ae-home-intro-textual-slogan {
    font-size: 23px !important;
    padding: 20px !important;
  }
  .mesp-ae-home-new-btn {
    padding: 25px 60px !important;
    font-size: 25px !important;
  }
}

img.stand-img {
  /* height: 100vh; */
  width: 100% !important;
}
.stand-item-container .visible.content,
.stand-item-container .hidden.content {
  width: 100%;
}
.stand-item-container .content {
  background: #31b89b !important;
  color: #fff !important;
}
.btn.btn-primary.view-stand-btn.active {
  width: 75%;
  background: #1a5691 !important;
  background: transparent !important;
  border-color: #fff;
}
.header.stand-header {
  color: #fff !important;
}
.view-stand-btn.active i {
  font-size: 31px;
  width: inherit;
}
.extra-btn-action span.right.floated,
.extra-btn-action span.left.floated {
  font-size: 12px;
  display: grid;
  vertical-align: middle !important;
  text-align: center;
  padding: 5px;
}
.extra-btn-action span.right.floated:hover,
.extra-btn-action span.left.floated:hover {
  border: 1px solid #1a5691;
  border-radius: 7px;
  background: transparent !important;
}
.extra-btn-action .right i {
  vertical-align: middle !important;
  display: table-cell;
}
.hall_name {
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}
.component-connexion-visiteur {
  height: inherit !important;
}

/* Accueil Configuration Stand */

.configuration-stand {
  width: 100%;
  background: linear-gradient(
    173deg,
    rgba(173, 109, 190, 0.8548553210346639) 0%,
    rgba(84, 78, 202, 0.8940710073091737) 93%
  );
  height: auto;
  /* background: violet; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 85px 150px;
}

.aide {
  color: #fff;
}

.focus-input100-upload {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100-upload::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #ad6dbe;
  font-size: 22px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 0px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100-upload::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  /* color: #fff; */
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-terminer {
  background-color: orange;
  color: #fff;
  border-radius: 0;
  padding: 10px 150px;
  border: 1px solid orange;
}
.btn-terminer:hover {
  background-color: #fff;
  color: orange;
  border-radius: 0;
}

.aide:hover {
  color: orange;
}

.titre-config-stand {
  font-weight: bold;
  line-height: 1;
}

.etape {
  border: 1px solid #fff;
}

.etiquette {
  background: orange;
  /* width: 50px;
  height: 50px; */
  color: #fff;
  font-weight: bold;
  padding: 10px;
  display: inline-block;
}

.icon-etape {
  /* padding: 25px; */
  /* margin: 10% 15% 1% 15%; */
  line-height: 1;
}

.img-stand-config {
  width: 100%;
}

.esp-txt-etape {
  padding: 18px;
}

.hauteur-txt-etape {
  height: 204px;
}

.hauteur-titre {
  height: 63px;
}

.btn-demarrer {
  background-color: orange;
  color: #fff;
  border-radius: 0;
  padding: 10px 30px;
  border: 1px solid orange;
}
.btn-demarrer:hover {
  background-color: #fff;
  color: orange;
  border-radius: 0;
  padding: 10px 30px;
}

.label {
  display: block;
  width: 100%;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2.5em;
  border: 1px solid #ad6dbe;
  height: 60px;
}

.label:active {
  background-color: mediumaquamarine;
}

.input-upload {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  padding: 0;
  width: 0px;
  margin-left: 50px;
  line-height: 3;
}

.color-grey {
  color: rgb(177, 177, 177);
}
.color-theme {
  color: #273375 !important;
}

/* Accueil */

.header-accueil {
  background: #fff;
}

.logo-accueil {
  width: 80%;
}
.color-menu-mobile {
  color: #ad6dbe !important;
}

.nav-mobile {
  padding: 0px 10px;
}

.btn-connexion-acc {
  background: #e8b400;
  padding: 10px 20px;
  border-radius: 0;
  font-weight: 600;
  border: 1px solid #e8b400;
}

.btn-connexion-acc:hover {
  background: #fff;
  color: #e8b400 !important;
}
.btn-inscription-acc {
  background: #00c29e;
  padding: 10px 20px;
  border-radius: 0;
  font-weight: 600;
  /* border: 1px solid #00C29E; */
}

.btn-inscription-acc:hover {
  background: #fff !important;
  color: #ad6dbe !important;
}

.btn-connexion-acc-mob {
  background: #e8b400;
  padding: 7px 15px;
  border-radius: 0;
  font-weight: 600;
  border: 1px solid #e8b400;
}

.btn-connexion-acc-mob:hover {
  background: #fff;
  color: #e8b400 !important;
}
.btn-inscription-acc-mob {
  background: #00c29e;
  padding: 7px 15px;
  border-radius: 0;
  font-weight: 600;
  border: 1px solid #00c29e;
}

.btn-inscription-acc-mob:hover {
  background: #fff;
  color: #00c29e !important;
}

.padd-menu-accueil {
  padding: 3% 10%;
}

/* top */

.padd-menu {
  padding: 2% 10%;
}

.logo-soushaulle {
  font-size: 30px;
}

.menu-secondaire {
  width: 100%;
  height: auto;
}

.display-inl-b {
  display: inline-block;
}

.padd-top {
  padding: 2% 5% 5% 5%;
}
.padd-titre {
  padding: 5% 0% 10% 0%;
}

/* HALLES */
.header {
  padding: 2%;
}
.focus {
  top: 5px !important;
}
button.btn.btn-inprogress {
  width: 100%;
  background: #ff9800;
}
.component-liste-favoris-by-visiteur,
.component-liste-favoris-by-visiteur .s-h-primo {
  height: 100vh;
}
.input100-c-r {
  /* font-family: Poppins-Medium; */
  font-size: 16px;
  color: #333333;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 55px;
  background: none;
}
.color-theme-1 {
  color: #b884c8 !important;
}
.col-md-12.padd-titre.m-l-r-auto {
  margin-top: 15%;
}

span.uf-label {
  cursor: pointer;
  color: #1a5691;
  font-weight: bold;
  position: absolute;
  left: 108px;
  top: 43px;
}
.file-box {
  position: absolute;
  width: 100px;
  height: 100px;
  /* background: red; */
  /* padding: 16px; */
  border: 2px solid #1a5691;
  color: #1a5691;
  font-size: 63px;
  text-align: center;
  top: 0;
}
input.form-control.file-input {
  opacity: 0;
  opacity: 0;
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  z-index: 99999;
}
.form-group.sumit-btn-container {
  margin-top: 233px;
}
.avatar-preview-container {
  position: relative;
}
.error-alert {
  text-align: center !important;
}
.radio-btn-label {
  margin-right: 23px;
}

.avatar-error {
  top: 125px;
}
.sdz-mt-60 {
  margin-top: 60px;
}
/* img.logo-inscription {
  width: 250px;
} */
ul.nav.navbar-nav.ml-auto.nav-mobile {
  padding-left: 0 !important;
}

ul.nav.navbar-nav.ml-auto.nav-mobile {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.view-mobile-unique li.nav-item {
  text-align: left !important;
  border-bottom: 1px solid #e8e8e8;
}

.nav-mobile li:first-child {
  border-top: 1px solid #e8e8e8;
}

.view-mobile-unique li.nav-item a.nav-link.menu.fs-20 {
  font-size: 19px !important;
  padding: 14px 48px;
  color: #000 !important;
}

.view-mobile-unique li.nav-item:hover .nav-link {
  background: #8459a1 !important;
  color: #fff !important;
  font-weight: 600;
}

.inscription-left ::-webkit-input-placeholder {
  /* Edge */
  color: white !important;
}

.inscription-left :-ms-input-placeholder {
  /* Internet Explorer */
  color: white !important;
}

.inscription-left ::placeholder {
  color: white !important;
}

/**************************************************************/
/* Ordinateur */

/* @media(min-width:759px){
  .logo-accueil-mobile{
    display: none !important;
  }
  .view-mobile-unique{
    display: none !important;
  }
  .img-apropos{
    width: 100% !important;
    height: auto;
    margin-left: -110px;
    position: absolute;
  }
  .mt-avantage {
      margin-top: 0rem;
  }
  .modal-lg, .modal-xl {
    max-width: 500px !important;
  }
} */

@media (max-width: 1220px) {
  /* .esp-txt-etape {
  padding: 20px 10px !important;
} */
  .font-rest {
    font-size: 10px !important;
  }

  .font-titre-rest {
    font-size: 15px !important;
  }
  .bordure-bas {
    margin: 0% 4% !important;
  }
  .bordure-inscription {
    padding: 50px 25px !important;
  }
  .bordure-inscription-l-r {
    padding: 40px !important;
  }
  .p-l-r-100-etapes {
    padding: 0px !important;
  }
}

/* Smartphone & Tablettes */
@media (max-width: 758px) {
  .btn-compte-valider {
    margin-top: 10px !important;
  }
  .p-l-r-0-m {
    padding: 0px !important;
  }

  .p-l-r-100-etapes {
    padding: 0px !important;
  }
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .apropos {
    background: rgb(133, 90, 163);
    background: linear-gradient(
      190deg,
      rgba(133, 90, 163, 1) 0%,
      rgba(95, 148, 205, 1) 100%
    );
    padding: 5% 0% 10% 0% !important;
  }
  .b-apropos {
    background: #fff;
    display: inline-flex;
    padding: 5% 0% 15% 0% !important;
  }

  .center-mobile {
    text-align: center !important;
  }

  .fixed-top {
    display: none !important;
  }
  .view-web-unique {
    display: none !important;
  }

  .none-mobile {
    display: none !important;
  }

  .logo-accueil-mobile {
    width: 100% !important;
  }

  .color-grey {
    color: rgb(177, 177, 177);
    font-size: 15px !important;
    height: 45px;
  }

  .image-mob {
    margin-bottom: 5px !important;
    font-size: 15px !important;
  }

  .modal-config {
    padding: 6% !important;
  }

  .taille {
    color: rgb(224, 224, 224);
    margin-left: 10%;
    font-size: 12px;
  }

  /* .mt-5{margin-top: 0 !important;} */
  .btn-terminer {
    width: 100% !important;
  }

  .taille {
    color: rgb(224, 224, 224);
    font-size: 15px !important;
    position: absolute;
    right: 20px;
    top: 17px !important;
  }

  .configuration-stand {
    width: 100% !important;
    padding: 85px 15px !important;
  }

  .bordure-connexion {
    padding: 20px 0 !important;
  }
  .bordure-connexion-l-r {
    padding: 50px 30px !important;
  }

  .comp-mobile {
    margin-left: 11% !important;
  }

  .pl-0-m {
    padding-left: 15px !important;
  }

  .titre1-connexion {
    font-size: 26px !important;
    text-align: center;
    margin: 0;
    line-height: 1;
    font-weight: 600;
  }

  .titre2-connexion {
    font-size: 13px !important;
    text-align: center;
    letter-spacing: 3px;
    margin: 0;
    font-weight: 600;
  }

  .bordure-inscription {
    padding: 50px 0 !important;
  }
  .bordure-inscription-l-r {
    padding: 50px 25px !important;
  }
  .img-top {
    width: 100% !important;
    background: linear-gradient(
        190deg,
        rgba(133, 90, 163, 0.804435152967437) 0%,
        rgba(133, 90, 163, 0.6475724078693977) 100%
      ),
      url("./img/bg/top.jpg");
    height: auto;
    /* background: violet; */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .padd-menu {
    display: none !important;
  }

  .menu {
    font-size: 25px !important;
    color: #fff !important;
  }

  .menu-mobile {
    padding: 15px !important;
    background: #fff !important;
  }

  .menu:hover {
    font-size: 25px !important;
    color: #fff;
    text-decoration: underline;
  }

  .display-inl-b {
    display: inline-block !important;
  }

  .padd-top {
    padding: 2% 5% 5% 5% !important;
  }
  .padd-titre {
    padding: 12% 0% 10% 0% !important;
  }

  .titre1 {
    font-size: 31px !important;
    text-align: center;
    margin: 0;
    line-height: 1;
    font-weight: 600;
  }
  .titre2 {
    font-size: 16px !important;
    text-align: center;
    letter-spacing: 3px !important;
    margin: 0;
    font-weight: 600;
  }

  /* partenaires */

  .partenaires {
    padding: 6% 5% 4% 5% !important;
    background: #53a8dc;
  }

  .b-partenaires {
    background: #fff;
    display: inline-flex;
  }

  .img-partenaires {
    width: 100% !important;
  }

  .p-partenaires {
    padding: 10px !important;
  }

  /* Avantages */

  .avantages {
    background: #599bd1;
    padding: 6% 5% 4% 5%;
  }

  .p-5 {
    padding: 0 !important;
  }
  .logo-inscription {
    width: 40% !important;
  }

  /* Compteur */
  .wrapper {
    position: relative;
    height: 10em;
  }

  .countdown-container {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .countdown {
    display: flex;
    transform-style: preserve3d;
    perspective: 500px;
    height: 6rem;
    width: 100%;
    margin: 0 auto;
  }
  .countdown.remove {
    animation: hide-countdown 1s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
    overflow: hidden;
  }

  .number,
  .separator {
    display: block;
    color: #fff;
    height: 4rem;
    font-size: 2rem;
    position: relative;
    line-height: 10rem;
    text-align: center;
    width: 100%;
  }

  .separator {
    margin: 0;
    width: 2rem;
  }

  .new,
  .old,
  .current {
    color: #e5b336;
    position: absolute;
    border-radius: 1rem;
    height: 10rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .new {
    animation: show-new 0.4s cubic-bezier(0, 0.9, 0.5, 1.2) forwards;
  }

  .old {
    animation: hide-old 2s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
  }

  .countdown section {
    position: relative;
  }

  #js-days:after,
  #js-hours:after,
  #js-minutes:after,
  #js-seconds:after {
    content: "JOURS";
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: -80px;
    font-size: 13px;
  }
  #js-hours:after {
    content: "HEURES";
  }
  #js-minutes:after {
    content: "MINUTES";
  }
  #js-seconds:after {
    content: "SECONDES";
  }
  @keyframes hide-countdown {
    to {
      height: 0;
      overflow: hidden;
    }
  }
  @keyframes show-new {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(-2rem) scale(0.8)
        rotateX(-20deg);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }
  @keyframes hide-old {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(-5rem) scale(0.5)
        rotateX(-75deg);
    }
  }

  .mt-m-5 {
    margin-top: 0 !important;
  }

  .primo {
    background: rgb(136, 71, 218);
    background: linear-gradient(
      156deg,
      rgba(136, 71, 218, 0.9528945367209384) 0%,
      rgba(26, 188, 254, 0.6167600829394257) 100%
    );
    width: 100%;
    padding: 5% 0% 5% 0%;
  }
  .bordure-bas {
    margin: 0% 4%;
  }
}

.col-md-4.deconnexion-container {
  text-align: center;
}

.deconnexion {
  line-height: 3;
  color: #8459a1;
  cursor: pointer;
}

.sdz-violet-bg {
  background: #8459a1 !important;
}
.sdz-violet-color {
  color: #8459a1 !important;
}
.sdz-green-bg {
  background: #31b89b !important;
}
.sdz-green-color {
  color: #31b89b !important;
}
.sdz-lblue-bg {
  background: #46b9e8 !important;
}
.sdz-lblue-color {
  color: #46b9e8 !important;
}
.sdz-fblue-bg {
  background: #1a5691 !important;
}
.sdz-fblue-color {
  color: #1a5691 !important;
}
.go-back {
  cursor: pointer !important;
}

.bordure-bas {
  border-bottom-color: transparent !important;
}
.inbox-container {
  margin: 0% 10%;
}
.component-conseiller-chatroom .conseiller-inbox-container {
  position: absolute;
  width: 100%;
  top: 15%;
}
.bordure-bas {
  border-bottom-color: #ffffff47 !important;
  /* padding-bottom: 51px !important; */
}
.react-cookie-law-option-wrapper {
  display: none !important;
}

a.react-cookie-law-policy {
  display: none !important;
}

.react-cookie-law-dialog {
  background: #273375 !important;
}

.react-cookie-law-msg {
  color: #fff !important;
  font-size: 10px !important;
  text-align: center;
  padding: 10px !important;
}
.react-cookie-law-accept-btn {
  background-color: #fff !important;
  color: #273375 !important;
  padding: 12px !important;
  font-weight: 900 !important;
  border-radius: 10px !important;
}
.react-cookie-law-container {
  display: flex;
  align-items: center;
}
.react-cookie-law-accept-btn:hover {
  border: 1px solid #fff !important;
  background: transparent !important;
  color: #fff !important;
}
.avt-textuel {
  text-align: justify !important;
  padding: 16px;
  color: #fff;
}
.avantage-item:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}
span.ytb_title {
  background: #8459a1;
  color: #fff;
  display: block;
  padding: 25px;
}

.sweet-alert a.btn.btn-lg.btn-primary {
  padding: 20px 15px !important;
  font-size: 15px;
  width: 16%;
  border: 1px solid #fff;
  background: linear-gradient(
    259deg,
    rgba(173, 109, 190, 1) 0%,
    rgba(84, 78, 202, 1) 93%
  );
  border-radius: 0;
  color: #fff !important;
  border: 1px solid rgba(84, 78, 202, 1);
}

.sweet-alert a.btn.btn-lg.btn-primary:hover {
  background: #fff !important;
  color: rgb(173, 109, 190) !important;
  text-decoration: none;
  border: 1px solid rgb(173, 109, 190);
}
.mag-cont img {
  cursor: pointer;
}
.search-container {
  display: none;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
.pulse-podium {
  animation: shadow-pulse 1s infinite;
}
.pointer {
  cursor: pointer;
}
.hide-item {
  display: none;
}
header.header.visiteur-header {
  max-height: 250px;
  transition: max-height 0.15s ease-out;
}
.col-md-4.offset-md-4.search-input-container {
  z-index: 99999;
  background: white;
  background-clip: content-box;
  position: relative;
  transition: max-height 0.25s ease-in;
}
.autocomplety {
  overflow: overlay;
  max-height: 350px;
  /* overflow-y: hidden; */
  /* overflow-x: hidden; */
}
a.btn-apropos.uppercase.display-inl-b.box-shadow.mb-4.wow.bounceInDown {
  background: #31b89b;
  border-color: #31b89b;
}

/* Modal styling */
.modal-content.oe-modal-content {
  border-radius: 12px;
}
.modal-header.oe-modal-header {
  background: #273375 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  padding: 15px;
}
.infos-btn {
  background: #273375 !important;
  margin: 30px 0;
  padding: 15px 35px;
  color: #ffffff !important;
  font-weight: 600;
  border-color: #273375 !important;
  width: 100%;
}
.infos-btn:hover {
  background: transparent !important;
  color: #273375 !important;
}
.oe-modal-title {
  width: 100%;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase !important;
}
/* Buttons Custom Style */
.oe-primary-btn {
  width: 100%;
  height: 55px;
  border-radius: 50px;
  margin: 15px 0 !important;
  background: #273375 !important;
  color: #fff !important;
  font-weight: 600;
  border-color: #273375 !important;
}
.oe-primary-btn:hover {
  background: transparent !important;
  color: #273375 !important;
}
.oe-primary-link {
  padding: 10px;
  display: block;
  margin: 6px 0;
  border: 1px solid #273375;
  border-radius: 20px;
  font-size: 18px;
  color: #fff;
  background: #273375;
  text-align: center;
  font-weight: 600;
}
.oe-primary-link:hover {
  background: transparent !important;
  border-color: #273375 !important;
  color: #273375 !important;
}
.talk-to-form-container-row {
  padding: 10px 150px;
}
.oe-form-control {
  background-image: none !important;
  border: 1px solid #273375 !important;
  font-size: 18px;
  padding: 23px 15px;
  border-radius: 10px;
}
.v2-select-form-control {
  height: 50px !important;
  padding: 0 15px;
}
.o-form-group label {
  font-size: 18px;
  margin-left: 16px;
  color: #273375 !important;
  font-weight: 600;
}
.linkable {
  cursor: pointer !important;
}
.oe-alert-warning {
  text-align: center;
  font-weight: 800;
}
h6.h-capitalise {
  text-transform: capitalize !important;
  font-size: 18px;
}
.open-chat-btn-row {
  width: 100%;
}
.oe-alert-danger {
  border-radius: 50px !important;
}
.empty-badge-alert {
  width: 100%;
  font-size: 20px;
  background: #1f497d85;
  border-color: #1f497d;
  border-radius: 14px;
}
.empty-badge-alert p {
  color: #1f497d;
}
.info-label {
  font-weight: 600;
}
.empty-alert-row {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #273375 !important;
  border-radius: 20px;
  padding: 20px;
  background: #27337550 !important;
}
.oe-tab-row.row {
  width: 100%;
}
.empty-textual-col {
  text-align: left;
}
.empty-alert-icon {
  /* width: 80px;
  height: 80px; */
  color: #1f497d;
}
.empty-textual-col p {
  color: #273375 !important;
}
.empty-textual-col p strong {
  font-weight: 600;
}

.action-btn {
  border-radius: 15px;
  padding: 10px;
  color: #fff !important;
}
.action-btn.edit-btn {
  background: #ff9800 !important;
  border-color: #ff9800 !important;
}
.action-btn.edit-btn:hover {
  background: #fff !important;
  color: #ff9800 !important;
}
.action-btn.delete-btn {
  background: rgb(230, 65, 75) !important;
  border-color: rgb(230, 65, 75) !important;
}
.action-btn.delete-btn:hover {
  background: #fff !important;
  color: rgb(230, 65, 75) !important;
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 930px;
  }
}
.react-bs-table-csv-btn {
  background: #273375 !important;
  border-color: #273375 !important;
  padding: 13px;
  border-radius: 50px;
  color: #fff !important;
  font-weight: 600;
}
.react-bs-table-csv-btn:hover {
  background: #fff !important;
  color: #273375 !important;
}
.orm-table-container .react-bs-table-container .react-bs-table-search-form {
  margin-bottom: 0;
  top: 20%;
}
.react-tabs__tab--selected {
  color: #fff !important;
  background: #273375 !important;
  border-color: #273375 !important;
}
.react-tabs__tab {
  font-size: 20px;
  font-weight: 600;
  color: #273375;
}
.oe-tab-container {
  padding: 15px;
}
.react-tabs__tab-list {
  border-bottom: 3px solid #273375 !important;
}
.orm-badge-success,
.orm-badge-warning,
.orm-badge-danger {
  font-size: 15px;
  border-radius: 50px;
  padding: 10px;
}
.oe-alert-info {
  background: rgb(35 101 173 / 38%) !important;
  border-color: #273375 !important;
  color: #273375 !important;
  border-radius: 10px !important;
}
.oe-alert-info p {
  color: #273375;
}
.gLink-created-container {
  font-weight: 600;
}
li.active.page-item .page-link {
  background: #273375 !important;
  border-radius: 50% !important;
  padding: 8px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #fff !important;
}
li.page-item a.page-link {
  color: #273375 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.action-btn.show-btn {
  background: #273375 !important;
  border-color: #273375 !important;
}
.action-btn.show-btn:hover {
  background: #fff !important;
  color: #273375 !important;
}
.click-to-copy {
  background: rgb(117, 196, 107);
  padding: 5px 25px;
  border-radius: 50px;
  color: #fff !important;
  font-weight: 600;
}
.linkable-item:hover {
  color: #273375 !important;
  font-weight: 600 !important;
}
.sh-row {
  width: 100%;
  align-items: center;
}
.logo-soushaulle p {
  text-align: center;
}
.col-md-3.sh-col.clock-col {
  text-align: center;
  color: #fff;
}
.component-local-clock {
  font-size: 20px;
  font-weight: 900;
}
.timezone-title {
  color: rgb(253, 247, 244) !important;
  font-weight: 600;
  font-size: 15px !important;
}
button.add_to_screen_btn {
  position: fixed;
  top: 0;
  z-index: 9999;
  padding: 20px;
  text-align: center;
  width: 100%;
  background: #273375;
  color: #fff;
  font-weight: 600;
}
.orientation-options-component {
  margin: 15px;
}
.datepicker-col label {
  display: block;
}
.datepicker-col div {
  width: 100%;
}
.event-date-checkout-container {
  width: 100%;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: inherit !important;
}
.event-date-checkout {
  padding: 10px 20px;
  background: #273375 !important;
  border-radius: 25px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 45%;
  justify-content: space-between;
  margin-bottom: 10px;
}
.date-choice-label {
  font-size: 20px;
  color: #273375 !important;
  font-weight: 600;
  text-transform: uppercase;
}
.add-event-col.event-checkout-form-infos {
  background: #27337550;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}
.choosed-datetime-infos {
  color: #c10046 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
.count-remaining {
  background: #ffffff;
  padding: 8px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  width: 35%;
  color: #c10046 !important;
}
.checkout-radion-label {
  width: 100%;
  line-height: 22px;
}
input[type="radio"].checkout-radio-btn-item {
  width: 30px !important;
  height: 30px !important;
}
.half-top-accueil.fixing-after-header.gl-form-container {
  padding-bottom: 100px;
}

/* .v_event_checkout_btn_container {
  margin-top: 20px;
} */
.gl-checkout-btn-col-container .checkout-icon {
  width: 24px;
  height: 24px;
}
.daty {
  font-size: 17px;
  font-weight: 600;
}
.gl-checkout-btn-col-container .checkout-btn {
  border-radius: 40px;
}
.gl-checkout-action-btn-row .event-date-item {
  border-bottom: 1px solid #fff;
}
.gl-checkout-action-btn-row .event-date-item:first-child {
  border-top: 1px solid #fff;
}
.gl-checkout-btn-col-container .checkout-btn {
  display: flex;
  justify-content: space-around;
}
.gl-remaining {
  background: #16b916;
  padding: 4px 25px;
  border-radius: 30px;
  font-weight: 600;
}
.date-pcounting-container {
  text-align: left;
  padding: 8px;
  background: #f1f9ff;
  margin-bottom: 7px;
  position: relative;
  border-radius: 10px;
  font-size: 15px;
}
.date-pcounting-content {
  background: #273375 !important;
  padding: 7px 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 3%;
}
.eventies_date {
  text-align: justify;
  padding: 8px;
  font-size: 15px;
  background: #273375 !important;
  margin-bottom: 5px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
}
.date-pcounting-container:hover {
  background: #273375 !important;
  color: #fff !important;
}
.linkable.eventies_date:hover {
  background: transparent !important;
  color: #273375 !important;
  border: 1px solid #273375 !important;
}
.ready-remaining {
  background: #ff00009e;
  padding: 8px;
  border-radius: 5px;
  font-size: 17px;
  text-align: center;
  font-weight: 600;
}

.checkout-btn.background-opacity {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resetOK-container .rp-success-message {
  border-radius: 10px;
}

.resetOK-container .rp-success-message p {
  text-align: justify;
}

.resetOK-container a.btn.btn-clic-to-redirect {
  background: #273375 !important;
  color: #fff;
  padding: 15px;
  width: 100%;
  font-weight: 600;
}
.resetOK-container .btn-clic-to-redirect:hover {
  background: transparent !important;
}
.show-notif-item-label-container {
  padding: 7px 0;
}
.labelyiste {
  font-weight: 900;
}
.show-notif-item-contenu-body {
  padding: 7px 0;
}
.show-notif-item-contenu-body > div > div {
  padding: 5px 0;
}
.signature-email {
  font-weight: 900;
}
.export-to-xlsx-container.linkable span {
  padding: 12px;
  background: #273375 !important;
  margin-bottom: 7px;
  position: relative;
  font-size: 15px;
  color: #fff;
  width: 100% !important;
  display: block;
  font-weight: 600;
  border: 1px solid #ffffff;
  border-radius: 50px;
  text-align: center;
}
.export-to-xlsx-container.linkable {
  margin-bottom: 11px;
}
.export-to-xlsx-container.linkable span:hover {
  background: #f1f9ff !important;
  color: #273375 !important;
}
.react-bs-table-bordered {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5% !important;
}
.export-jpo-inscription-btn {
  position: absolute;
  background: #273375 !important;
  color: #fff;
  padding: 15px;
  border-radius: 50px;
  font-weight: 900;
  top: 2%;
  z-index: 99999;
}
.oe-edit-btn {
  background: #ff9800;
}
button.action-btn {
  margin: 0 5px;
}

.form-actions-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-actions-btn-container button {
  width: 150px;
  padding: 10px;
  color: #fff !important;
  font-weight: 600;
}

.form-submit-btn-in-modal {
  background: #273375 !important;
  border-color: #273375 !important;
}

.form-submit-btn-in-modal:hover {
  background: transparent !important;
  color: #273375 !important;
}

.close-btn.modal-close-btn:hover {
  background: transparent !important;
  color: #f44336 !important;
}

.close-btn.modal-close-btn {
  border-color: #f44336 !important;
}
.menu-dropdown-parent {
  padding-left: 10px;
}
.menu-dropdown-parent-container {
  color: #273375 !important;
}
.menu-dropdown-parent-button {
  color: #273375 !important;
  padding: 0;
}
.case.sn-sd-mb.logout-link-container {
  color: red;
}
.logout-menu.logout-link i {
  color: red;
}
.color-menu.logout-label {
  color: red !important;
  font-weight: 600;
}

.navbar-vertical-customisation-v2.no-view-mobile {
  height: 100%;
  left: 0;
  max-width: 15%;
  min-height: 300px;
  padding: 40px 0 150px;
  position: fixed;
  right: 0;
  top: 86px;
  z-index: 999;
  background: #fff !important;
  color: #273375 !important;
  overflow: scroll;
}
.nav-link.nav-link-vertival-custom {
  display: flex;
  align-items: center;
  padding: 15px 5px;
  text-transform: inherit !important;
  font-size: 16px;
}
.hiddenable-navlink-label {
  padding-left: 5px;
}
.nav-link.nav-link-vertival-custom.actived {
  background: #273375 !important;
  color: #fff !important;
  font-weight: 600;
}
.nav-link.nav-link-vertival-custom.actived svg path {
  fill: #fff !important;
}
#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #273375 !important;
}
#sidebar ul li.active > a,
a[aria-expanded="true"] svg path {
  fill: #fff !important;
}
ul#collapseMenuLink_1 li a {
  padding-left: 2rem;
}
.hoverable:hover {
  box-shadow: none !important;
}
.evaluation-maanager-component .action-btn.edit-btn {
  color: #ff9800 !important;
  background: none !important;
}
.evaluation-maanager-component .action-btn .action-icon {
  width: 20px !important;
}
.evaluation-maanager-component .delete-item-btn {
  color: #ff0000 !important;
}
.evaluation-maanager-component .resend-item-btn {
  color: #380eb3 !important;
}
.evaluation-maanager-component .action-btn {
  padding: 5px !important;
}
.evaluation-manager-table table tbody tr td {
  font-size: 13px !important;
}
.admin-red-font {
  color: #ff0000;
}
.admin-green1-font {
  color: #00af51;
  font-weight: 600;
}
.private-planning-in-admin.admin-green1-font {
  background: #00af51;
  color: #fff;
  padding: 1px 3px;
  border-radius: 3px;
}
.view-private-planning-btn svg {
  width: 15px !important;
}
.admin-green2-font {
  color: #65b72f;
  font-weight: 600;
}
.private-planning-in-admin.admin-green2-font {
  background: #65b72f;
  color: #fff;
  padding: 1px 3px;
  border-radius: 3px;
}
.rapport-container-view-by-candidat p {
  margin-bottom: 10px;
  color: #000000;
}
.rapport-container-view-by-candidat a {
  font-weight: 500;
  color: #4d5495;
}
.rapport-container-view-by-candidat p:last-child {
  color: #c10046 !important;
  font-weight: 600;
}
.react-calendar {
  width: 100% !important;
  margin-bottom: 20px;
}

/* Responsive */

@media (max-width: 480px) {
  .infos-etudiant-item-value.double-note {
    flex-direction: column;
  }
  .double-note .score-item-value {
    margin: 1% 0;
  }
  .check-your-profile {
    font-size: 10px !important;
  }
  .auto-eval-next-item-btn-container {
    left: 31% !important;
    margin-top: -4% !important;
  }
  .home-icon-group {
    top: -15% !important;
  }
  .auto-eval-home-textual-content {
    font-size: 18px !important;
    margin-top: -5% !important;
  }
  .auto-eval-warning-title {
    font-size: 17px !important;
    line-height: 13px !important;
  }
  .second-step .auto-eval-home-textual-content p {
    font-size: 10px !important;
    line-height: 15px !important;
  }
  .second-step .auto-eval-home-textual-container {
    /* top: 19% !important; */
    top: 38% !important;
  }
  .second-step .auto-eval-next-item-btn-container {
    left: 32% !important;
  }
  .auto-eval-next-item-btn {
    font-size: 10px !important;
    line-height: 10px !important;
  }
  .v2-navbar-body {
    flex-direction: column;
  }
  .v2-user-login-btn-container {
    margin: 20px 0;
  }
  .v2-footer-content-body {
    flex-direction: column;
  }
  #v2-auto-eval-btn-section {
    bottom: -25% !important;
  }
  .carousel-item-legend-header {
    font-size: 35px !important;
    line-height: 40px !important;
    padding: 0 !important;
  }
  .carousel-item-legend-slogan {
    font-size: 25px !important;
    line-height: 35px !important;
    padding: 0 !important;
  }
  .v2-services-body-listing-items {
    flex-direction: column;
  }
  .v2-service-item-container {
    width: 100%;
  }
  #v2-services-section {
    padding: 60px 25px;
  }
  .v2-service-item-container.v2-orientation-services-container:after,
  .v2-service-item-container.v2-informations-services-container:after,
  .v2-service-item-container.v2-school-or-university-services-container:after {
    display: none;
  }
  #v2-events-carousel-section {
    padding: 60px 25px;
  }
  .v2-user-login-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .v2-events-carousel-container .carousel-indicators {
    right: -60% !important;
    left: 0;
    bottom: -9%;
  }
  .v2-events-carousel-container .carousel-control-prev {
    left: 0;
  }
  .v2-events-carousel-container .carousel-control-next {
    left: 10%;
  }
  .carousel-item-textual {
    top: 10%;
    left: 0;
    width: 100%;
  }
  .carousel-item-textual p {
    font-size: 15px;
  }
  .v2-modal-empty-badge-body {
    padding: 45px !important;
  }
  .carousel-item-legend-header {
    top: 23% !important;
  }
  .carousel-item-legend-slogan {
    top: 35% !important;
  }
  .slide-item-action-btn {
    padding: 10px 40px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .infos-etudiant-item-value.double-note {
    flex-direction: column;
  }
  .double-note .score-item-value {
    margin: 1% 0;
  }
  .auto-eval-warning-title {
    font-size: 17px !important;
    line-height: 13px !important;
  }

  .second-step .auto-eval-home-textual-content p {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .second-step .auto-eval-home-textual-container {
    top: 19% !important;
  }

  .auto-eval-next-item-btn {
    font-size: 10px !important;
    line-height: 10px !important;
  }
  .v2-navbar-body {
    flex-direction: column;
  }
  .v2-user-login-btn-container {
    margin: 20px 0;
  }
  .v2-footer-content-body {
    flex-direction: column;
  }
  #v2-auto-eval-btn-section {
    bottom: -25% !important;
  }
  .carousel-item-legend-header {
    font-size: 25px;
    line-height: 25px;
    padding: 15px 35px;
  }
  .carousel-item-legend-slogan {
    font-size: 15px;
    line-height: 17px;
    padding: 0px 35px;
  }
  .v2-services-body-listing-items {
    flex-direction: column;
  }
  .v2-service-item-container {
    width: 100%;
  }
  #v2-services-section {
    padding: 60px 25px;
  }
  .v2-service-item-container.v2-orientation-services-container:after,
  .v2-service-item-container.v2-informations-services-container:after,
  .v2-service-item-container.v2-school-or-university-services-container:after {
    display: none;
  }
  #v2-events-carousel-section {
    padding: 60px 25px;
  }
  .v2-user-login-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .v2-events-carousel-container .carousel-indicators {
    right: -60% !important;
    left: 0;
    bottom: -9%;
  }
  .v2-events-carousel-container .carousel-control-prev {
    left: 0;
  }
  .v2-events-carousel-container .carousel-control-next {
    left: 10%;
  }
  .carousel-item-textual {
    top: 10%;
    left: 0;
    width: 100%;
  }
  .carousel-item-textual p {
    font-size: 15px;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    left: 0% !important;
    display: flex !important;
    justify-content: center !important;
    /* margin-right: 16% !important; */
  }
  .return-back-label {
    display: none !important;
  }
  .auto-eval-next-item-btn-container {
    left: 35%;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .auto-eval-home-textual-content {
    margin-top: -6% !important;
    font-size: 40px !important;
  }
  .auto-eval-next-item-btn-container {
    left: 32% !important;
  }
  .auto-eval-warning-title {
    font-size: 35px !important;
    line-height: 37px !important;
  }
  .second-step .auto-eval-next-item-btn-container {
    left: 32% !important;
  }
  .second-step .auto-eval-home-textual-content {
    margin-top: 0 !important;
  }
  .warning-confirm-input-group {
    flex-direction: column !important;
  }
  .second-step .auto-eval-home-textual-content p {
    font-size: 20px;
    line-height: 30px;
  }
  .v2-navbar-body {
    flex-direction: column;
  }
  .v2-user-login-btn-container {
    margin: 20px 0;
  }
  .v2-footer-content-body {
    flex-direction: column;
  }
  #v2-auto-eval-btn-section {
    bottom: -7% !important;
  }
  .carousel-item-legend-header {
    font-size: 40px;
    line-height: 45px;
    padding: 30px !important;
  }
  .carousel-item-legend-slogan {
    font-size: 25px;
    line-height: 17px;
    padding: 20px !important;
  }
  .v2-services-body-listing-items {
    flex-direction: column;
  }
  .v2-service-item-container {
    width: 100%;
  }
  #v2-services-section {
    padding: 60px 25px;
  }
  .v2-service-item-container.v2-orientation-services-container:after,
  .v2-service-item-container.v2-informations-services-container:after,
  .v2-service-item-container.v2-school-or-university-services-container:after {
    display: none;
  }
  #v2-events-carousel-section {
    padding: 60px 25px;
  }
  .v2-user-login-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .v2-events-carousel-container .carousel-indicators {
    right: -60% !important;
    left: 0;
    bottom: -9%;
  }
  .v2-events-carousel-container .carousel-control-prev {
    left: 0;
  }
  .v2-events-carousel-container .carousel-control-next {
    left: 10%;
  }
  .carousel-item-textual {
    top: 10%;
    left: 0;
    width: 100%;
  }
  .carousel-item-textual p {
    font-size: 15px;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    left: 0% !important;
    display: flex !important;
    justify-content: center !important;
    /* margin-right: 16% !important; */
  }
  .return-back-label {
    display: none !important;
  }
  .carousel-item-legend-slogan {
    bottom: 37% !important;
  }
  .carousel-item-legend-container {
    padding: 40px 0 !important;
  }
}

@media print {
  #v2-footer,
  .export-profile-pdf-btn {
    display: none;
  }
}

@media (max-width: 640px) {
  .mesp-ae-home-intro-textual {
    font-size: 20px !important;
  }
  .mesp-ae-home-intro-textual-slogan {
    font-size: 10px !important;
  }
  .mesp-ae-home-new-btn {
    margin: 0 !important;
    padding: 5px 25px !important;
    font-size: 16px !important;
  }
  .o-row.talk-to-form-container-row {
    padding: 0 !important;
  }
  .v2-modal-body-content-container {
    padding: 15px !important;
  }
  .calendar-select-result-md-col {
    margin-top: 35px !important;
  }
}

.react-loading-skeleton.news-image-skeleton {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.v2-footer-newsletter-form-control {
  background-image: none !important;
  border: 1px solid #ffffff !important;
  color: #ffffff;
}
.v2-footer-newsletter-form-control:focus {
  color: #ffffff !important;
}
.headering-container {
  margin-top: 10% !important;
  margin-left: 15% !important;
}
.showing-image {
  width: 100%;
}
.value-container {
  hyphens: auto;
  word-wrap: break-word;
  white-space: normal;
  text-align: justify;
}
.bordure-bas {
  border-bottom: 1px solid #fff;
  padding: 1% 0 !important;
}
.bg-light {
  background-color: #fff !important;
}
.v2-carousel-container .empty-alert-row {
  margin: 6% 0;
}
section.v2-auto-eval-btn-section.empty_slide {
  bottom: -70% !important;
}
.add-event-form-control {
  border: 1px solid #273375 !important;
  padding: 15px !important;
}
.size-alert {
  text-align: center;
  display: block;
  color: red;
  font-style: italic;
}
.reding .v2-modal-body-item-btn,
.reding .v2-modal-body-item-btn-on-full-width {
  background: #c10046 !important;
}
.blueing .v2-modal-body-item-btn,
.blueing .v2-modal-body-item-btn-on-full-width {
  background: #273375 !important;
}
.checkRDVloader {
  width: 80px;
  height: 80px;
  border: 3px dotted #273375;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.checkRDVloader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #c10046;
  border-style: solid solid dotted;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.rdv-col-container .checkout-btn {
  display: block;
  padding: 15px;
}
.rdv-col-container .gl-checkout-btn-col-container {
  margin-bottom: 15px;
}
.rdv-type-name {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #273375 !important;
  font-weight: 600;
}
.rdv-date-and-hours {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}
table thead tr th,
table tbody tr td {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  /* text-align: center; */
}

.inprogress-loader {
  width: 22px;
  height: 22px;
  border-width: 3px;
  border-style: dashed solid solid dotted;
  border-color: #ff3d00 #ff3d00 transparent #ff3d00;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input.filter.text-filter.form-control {
  border: 1px solid #2365ad50 !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  padding: 15px 5px !important;
  line-height: 3px !important;
}
.infos-etudiant-item-value.notes-container {
  display: block !important;
}
.number-filter-comparator.form-control {
  border: 1px solid #2365ad50 !important;
  border-radius: 10px !important;
  font-size: 20px !important;
  padding: 5px !important;
  line-height: 20px !important;
  background-image: none !important;
}
.number-filter-input.form-control {
  border: 1px solid #2365ad50 !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  padding: 15px 5px !important;
  line-height: 3px !important;
  border-color: #2365ad50 !important;
}
.profil-etudiant-section.profil-view-by-cf-conseiller
  .ae-quizz-item-content-body {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-bottom: 25px;
}
.profil-etudiant-section.profil-view-by-cf-conseiller
  .ae-quizz-item-content-body
  h2 {
  margin-bottom: 0;
}
.section-total-summing {
  padding: 10px;
  background: #c10046 !important;
  text-align: center;
  color: #ffffff !important;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  flex-wrap: wrap;
}
.v2-front-modal-header.modal-header {
  background: #273375 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  padding: 18px;
}
.v2-front-modal.modal .modal-content {
  border-radius: 15px;
}
.v2-front-modal-title.modal-title {
  font-family: "Poppins";
  color: #ffffff;
  font-size: 20px;
  text-align: center !important;
  width: 100%;
  font-weight: 600;
}
.v2-front-modal-form-container label {
  margin-left: 0;
  font-size: 15px;
}
.v2-front-modal-footer.modal-footer {
  padding: 10px 40px;
}
.v2-front-modal-form-submit-btn {
  background: #273375 !important;
  font-family: "Poppins";
  color: #ffffff !important;
  font-size: 18px;
  border-radius: 10px;
}
.v2-front-modal-form-submit-btn:hover {
  background: transparent !important;
  color: #273375 !important;
}
.calendar-body-content span {
  height: 300px;
}
.component-generated-event-link-form nav {
  z-index: 999;
}
.export-to-xlsx-container.linkable.export-eval-to-excel span {
  background: #10793f !important;
  text-align: center !important;
  padding: 8px !important;
  margin-bottom: 0 !important;
  line-height: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #10793f !important;
  border-radius: 10px !important;
}
.export-to-xlsx-container.linkable.export-eval-to-excel {
  margin-bottom: 0 !important;
}
.export-to-xlsx-container.linkable.export-eval-to-excel span:hover {
  background: #ffffff !important;
  color: #10793f !important;
}
/* .carousel-item-container {
    height: 200px;
} */
.carousel-item-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.carousel-item-legend-container {
  width: 100%;
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v2-carousel-container {
  z-index: 99999;
  position: relative;
}
.homepage-carousel-item-row {
  display: flex;
  align-items: center;
}
.available-date {
  background: #273375 !important;
  border: 0.5px solid #ffffff !important;
  color: #ffffff !important;
  font-weight: 600;
  border-radius: 50%;
}
.empty-rdv-alert-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.meet-conseiller-btn {
  margin: 15px 0;
  width: 50%;
  background: #c10046;
  color: #ffffff !important;
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  font-family: "Poppins";
}
.ReactModalPortal {
  position: absolute;
  top: 20%;
  width: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}
.react-bs-table-search-form .search-field {
  border: 1px solid;
  padding: 21px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
.react-bs-table-search-form .search-field {
  border: 1px solid;
  padding: 21px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
.checkout-filter-results-textuals-body {
  margin: 20px 0;
}
.period-title {
  font-family: "Poppins";
  font-size: 18px;
  text-align: center;
  color: #c10046;
  font-weight: 600;
}
.event-type-type {
  font-family: "Poppins";
  text-align: center;
  margin: 10px 0;
  font-size: 15px;
  color: #273375 !important;
  font-weight: 600;
}
.export-filtered-checkout-to-excel span {
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  margin-bottom: 0 !important;
  background: #c10046 !important;
  padding: 10px;
  color: #ffffff;
  font-size: 15px;
  border-radius: 50px;
  font-weight: 600;
  border: 1px solid #c10046 !important;
  font-family: "Poppins";
}
.export-filtered-checkout-to-excel span:hover {
  border: 1px solid #c10046 !important;
  color: #c10046 !important;
  background: #c1004620 !important;
}
.reset-filter-btn {
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  margin-bottom: 0 !important;
  background: #273375 !important;
  padding: 10px;
  color: #ffffff;
  font-size: 15px;
  border-radius: 50px;
  font-weight: 600;
  border: 1px solid #273375 !important;
  width: 100%;
  font-family: "Poppins";
}
.reset-filter-btn:hover {
  background: #27337520 !important;
  color: #273375 !important;
}
.checkout-filter-results-table-col {
  margin-bottom: 0 !important;
}
.mesp-carousel-item-container {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#news-feed-section,
#v2-events-carousel-section,
#v2-services-section {
  padding: 60px !important;
}
.v2-navbar-container {
  padding: 5px 30px !important;
}
.export-filtered-checkout-to-excel {
  margin-bottom: 15px;
}
.required-input {
  color: #ff0000;
}
.is-invite-mail {
  color: #101111 !important;
}
.in-progress-ville {
  background: rgb(193, 0, 70, 20%);
  color: rgb(193, 0, 70) !important;
  font-weight: 600;
  border-radius: 10px;
  font-size: 17px;
  padding: 20px;
}
.choosed-ville-label {
  padding: 50px 20px;
  /* text-align: center; */
  font-size: 20px;
  color: #000000;
  font-weight: 600;
}
.choosed-ville-label strong {
  color: #c10046 !important;
  font-weight: 700;
  text-transform: uppercase;
}
.mparticipants-filter-container {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}
.mparticipants-filter-container .v2-admin-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
}
.floatable-add-btn {
  position: fixed;
  bottom: 10%;
  right: 0;
  z-index: 999;
}
.component-orientation-remplissage-manager {
  position: relative;
}
.filter-mparticipant-form-label {
  color: #c10046;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 10px;
  font-weight: 600;
}
.rmsc .dropdown-heading {
  background-image: none !important;
  border: 1px solid #273375 !important;
  font-size: 18px;
  padding: 23px 15px;
  border-radius: 10px;
}
.rmsc .dropdown-container {
  border: none;
}
.logout-btn-container {
  display: flex;
  justify-content: center;
}
.logout-btn {
  background: #e53e3e;
  color: #fff;
  border-radius: 30px;
  border: 1px solid #e53e3e;
}
.logout-btn:hover {
  background: #e53e3e60 !important;
  color: #e53e3e !important;
  font-weight: 900 !important;
}
.react-tel-input.phone-input {
  width: 100%;
  border: 1px solid #273375 !important;
  font-size: 18px;
  border-radius: 10px;
}
.react-tel-input.phone-input input {
  width: 100%;
  padding: 23px 45px;
  border-radius: 10px;
  background: transparent !important;
  color: #495057;
}
.react-tel-input.phone-input .flag-dropdown {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.action-btn.resend-btn {
  background: #273375 !important;
}
.pp-item-actions-btns-container {
  padding: 3px 5px;
  border: 1px solid #C10046 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 5px;
  background: #C1004640 !important;
}
